import {all, put, takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm'
import actions from './actions'
import {ParameterOperation} from '@isoAm/components/service/GlobalParameterService'
import openNotificationWithIcon from '@isoAm/components/Notification'


function* sagaManageParameters({payload}) {
    const {operationType, currentParameter, ownerParameters} = payload
    try {
        switch (operationType) {
            case ParameterOperation.Parameter_Retrieve:
                yield put(actions.manageParameterSuccess(null, ownerParameters))
                break
            case ParameterOperation.Parameter_Insert:
                yield put(actions.manageParameterSuccess(null, ownerParameters))
                break
            case ParameterOperation.Parameter_Update:
                let updatedParameters = [];
                ownerParameters.forEach(currentRow => {
                    if (currentRow.parameterId === currentParameter.parameterId) {
                        console.log(currentRow.parameterId)
                        updatedParameters.push(currentParameter);
                    } else {
                        updatedParameters.push(currentRow);
                    }
                });
                yield put(actions.manageParameterSuccess(currentParameter, updatedParameters))
                break
            case ParameterOperation.Parameter_EditData:
                yield put(actions.manageParameterSuccess(currentParameter, ownerParameters))
                break
            case ParameterOperation.Parameter_Edit_Cancel:
                yield put(actions.manageParameterSuccess(null, ownerParameters))
                break
            default:
                break
        }
    } catch (error) {
        console.log(error)
        openNotificationWithIcon('error', 'Automation error: sagaManageParameters - ', error.toString())
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.MANAGE_PARAMETER_ACTION, sagaManageParameters)])
}