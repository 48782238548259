import {all, put, takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm'
import actions from './actions'
import {DataOperation} from '@isoAm/components/service/ConnectionService'
import openNotificationWithIcon from '@isoAm/components/Notification'


function* sagaManageConnection({payload}) {
    const {operationType, currentConnection, ownerConnections} = payload
    try {
        switch (operationType) {
            case DataOperation.Retrieve:
                yield put(actions.manageDataSuccess(false, null, ownerConnections))
                break
            case DataOperation.Insert:
                yield put(actions.manageDataSuccess(true, null, ownerConnections))
                break
            case DataOperation.Update:
                let updatedConnections = [];
                ownerConnections.forEach(currentRow => {
                    if (currentRow.connectionId === currentConnection.connectionId) {
                        // console.log(currentRow.connectionId)
                        updatedConnections.push(currentConnection);
                    } else {
                        updatedConnections.push(currentRow);
                    }
                });
                yield put(actions.manageDataSuccess(false, currentConnection, updatedConnections))
                break
            case DataOperation.EditData:
                yield put(actions.manageDataSuccess(true, currentConnection, ownerConnections))
                break
            case DataOperation.Edit_Cancel:
                yield put(actions.manageDataSuccess(false, null, ownerConnections))
                break
            default:
                break
        }
    } catch (error) {
        console.log(error)
        openNotificationWithIcon('error', 'Automation error: sagaManageTestCase - ', error.toString())
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.MANAGE_DATA, sagaManageConnection)])
}

// let retrieveConnection = {}
// retrieveConnection.connectionId = currentConnection.connectionId;
// retrieveConnection.awsAccessKeyId = currentConnection.awsAccessKeyId;
// retrieveConnection.awsSecretAccessKey = currentConnection.awsSecretAccessKey;
// retrieveConnection.connectionHost = currentConnection.connectionHost;
// retrieveConnection.connectionPassword = currentConnection.connectionPassword;
// retrieveConnection.connectionPort = currentConnection.connectionPort;
// retrieveConnection.connectionServer = currentConnection.connectionServer;
// retrieveConnection.connectionType = currentConnection.connectionType;
// retrieveConnection.connectionUrl = currentConnection.connectionUrl;
// retrieveConnection.connectionUser = currentConnection.connectionUser;
// retrieveConnection.databasename = currentConnection.databasename;
// retrieveConnection.databaseschema = currentConnection.databaseschema;
// retrieveConnection.databasetype = currentConnection.databasetype;
// retrieveConnection.description = currentConnection.description;
// retrieveConnection.encoded = currentConnection.encoded;
// retrieveConnection.owner = currentConnection.owner;
// retrieveConnection.profilename = currentConnection.profilename;
// retrieveConnection.provider = currentConnection.provider;