import {combineReducers} from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import SuiteReducer from '@isoAm/redux/suite/reducer'
import TestCaseReducer from '@isoAm/redux/testcase/reducer';
import TestHistoryReducer from '@isoAm/redux/history/reducer';
import ConnectionReducer from '@isoAm/redux/connection/reducer';
import ParameterReducer from '@isoAm/redux/parameters/reducer';

export default combineReducers({
    Auth,
    App,
    ThemeSwitcher,
    Ecommerce,
    LanguageSwitcher,
    SuiteReducer,
    TestCaseReducer,
    TestHistoryReducer,
    ConnectionReducer,
    ParameterReducer
});
