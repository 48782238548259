import {all, put, takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm'
import actions from './actions'
import {testCaseOperation} from '@isoAm/components/service/testCaseService'
import openNotificationWithIcon from '@isoAm/components/Notification'

function* sagaManageTestCase({payload}) {
    const {operationType, currentTestCase, ownerTestCases} = payload
    let updatedTestCases = [];
    try {
        switch (operationType) {
            case testCaseOperation.Operation_Add_TestCase:
                yield put(actions.manageTestCaseSuccess(true, null, ownerTestCases))
                break
            case testCaseOperation.Operation_Edit_TestCase:
                yield put(actions.manageTestCaseSuccess(true, currentTestCase, ownerTestCases))
                break
            case testCaseOperation.Operation_Edit_Cancel_TestCase:
                yield put(actions.manageTestCaseSuccess(false, null, ownerTestCases))
                break
            case testCaseOperation.Operation_Set_ExecutionResult:
                yield put(actions.manageTestCaseSuccess(false, currentTestCase, ownerTestCases))
                break
            case testCaseOperation.Operation_Update_TestCase:
                ownerTestCases.forEach(testCase => {
                    if (testCase.testid === currentTestCase.testid) {
                        console.log(testCase.testid)
                        updatedTestCases.push(currentTestCase);
                    } else {
                        updatedTestCases.push(testCase);
                    }
                });
                yield put(actions.manageTestCaseSuccess(false, null, updatedTestCases))
                break
            case testCaseOperation.Operation_Insert_TestCase:
                ownerTestCases.push(currentTestCase);
                yield put(actions.manageTestCaseSuccess(false, null, ownerTestCases))
                break
            case testCaseOperation.Operation_Retrieve_TestSteps:
                let retrieveTestCase = {}
                retrieveTestCase.testid = currentTestCase.testid;
                retrieveTestCase.name = currentTestCase.name;
                retrieveTestCase.description = currentTestCase.description;
                retrieveTestCase.lastupdatedate = currentTestCase.lastupdatedate;
                retrieveTestCase.status = currentTestCase.status;
                retrieveTestCase.testCaseDetails = currentTestCase.testCaseDetails;
                yield put(actions.manageTestCaseSuccess(false, retrieveTestCase, ownerTestCases))
                break
            case testCaseOperation.Operation_Load_StepsFromExcel:
                yield put(actions.manageTestCaseSuccess(false, currentTestCase, ownerTestCases))
                break
            default:
                break
        }
    } catch (error) {
        console.log(error)
        openNotificationWithIcon('error', 'Automation error: sagaManageTestCase - ', error.toString())
    }
}

function* sagaManageTestSteps({payload}) {
    const {operationType, currentTestCase, ownerTestCases} = payload
    try {
        switch (operationType) {
            case testCaseOperation.Operation_Load_StepsFromExcel:
                yield put(actions.manageTestStepActionSuccess(true, currentTestCase, ownerTestCases))
                break
            default:
                break
        }
    } catch (error) {
        console.log(error)
        openNotificationWithIcon('error', 'Automation error: sagaManageTestCase - ', error.toString())
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.MANAGE_TEST_CASE, sagaManageTestCase)])
    yield all([takeEvery(actions.MANAGE_TEST_STEPS, sagaManageTestSteps)])
}