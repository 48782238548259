/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import siteConfig from '@isoAm/config/site.config'
import {isEmpty} from 'lodash'

export const owner = 'ik_usa@hotmail.com'
export const dateFormat = 'YYYY-MM-DD HH:mm:ss'

export function checkIsEmptyObject(checkValue) {
    let retValue = '';
    if (checkValue === null || checkValue === '') {
        retValue = ''
    } else if (checkValue === undefined) {
        retValue = null
    } else {
        retValue = checkValue;
    }
    return retValue;
}

export function checkIsEmptyNumber(checkValue) {
    let retValue = '';
    if (isEmpty(checkValue)) {
        retValue = 0
    } else {
        retValue = checkValue;
    }
    return retValue;
}

export const getHeader = () => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append('Origin', siteConfig.apiUrl)
    return headers
}

export const backEndApiPaths = {
    Suite_Get_By_Id_Path: '/suite/getsuitebyid',
    Suite_Get_By_Owner_Path: '/suite/getsuitebyowner',
    Suite_Delete_By_Id_Path: '/suite/postdeletesuite',
    Suite_Insert_Path: '/suite/postinsertsuite',
    Suite_Update_By_Id_Path: '/suite/putupdatesuite',
    Suite_TestCase_List_By_Owner_Path: '/suite/testcaseidlistbyowner',

    TestCase_GetById: '/testcase/getbytestid',
    TestCase_GetByOwner: '/testcase/getbyowner',
    TestCase_DeleteById: '/testcase/postdelete',
    TestCase_Insert: '/testcase/postinsert',
    TestCase_UpdateById: '/testcase/putupdate',
    TestCase_UpdateById_Post: '/testcase/postupdate',
    TestCase_Execute: '/testcase/execute',

    History_GetById: '/history/getbytestid',
    History_Insert: '/history/postinsert',
    History_GetByDate: '/history/getbydate',

    Connection_GetByOwner: '/testconnection/getbyowner',
    Connection_GetById: '/testconnection/getbyid',
    Connection_Insert: '/testconnection/postinsert',
    Connection_UpdateById: '/testconnection/postupdate',
    Connection_DeleteById: '/testconnection/postdelete',
    Connection_GetByProfile: '/process/getconnection',

    Parameter_GetByOwner: '/parameter/getbyowner',
    Parameter_GetById: '/parameter/getbyid',
    Parameter_Insert: '/parameter/postinsert',
    Parameter_UpdateById: '/parameter/postupdate',
    Parameter_DeleteById: '/parameter/postdelete',
}
