/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import siteConfig from '@isoAm/config/site.config'
import {backEndApiPaths, checkIsEmptyObject} from '@isoAm/config/automationConfig'
import openNotificationWithIcon from '@isoAm/components/Notification'

export function updateNullFields(dataList) {
    const testCaseList = []
    if (dataList) {
        for (let i = 0; i < dataList.length; i += 1) {
            const header1 = {}
            header1.testid = dataList[i].testid
            header1.name = checkIsEmptyObject(dataList[i].name)
            header1.description = checkIsEmptyObject(dataList[i].description)
            header1.owner = checkIsEmptyObject(dataList[i].owner)
            header1.lastupdatedate = checkIsEmptyObject(dataList[i].lastupdatedate)
            header1.totalsteps = checkIsEmptyObject(dataList[i].totalsteps)
            header1.testCaseDetails = dataList[i].testCaseDetails
            testCaseList.push(header1)
        }
    }
    return testCaseList
}

export function populateTestStepsFromExcel(testStepsList, testid) {
    const updatedCaseList = []
    let stepIdCount = 50
    if (testStepsList) {
        for (let i = 0; i < testStepsList.length; i += 1) {
            let testCaseDetail = {};
            try {
                // testCaseDetail.screen =
                // console.log("Got testStepsList + " + testStepsList[i])
                testCaseDetail.testid = testid
                testCaseDetail.screen = checkIsEmptyObject(testStepsList[i][1])
                testCaseDetail.stepid = stepIdCount
                testCaseDetail.description = checkIsEmptyObject(testStepsList[i][2])
                testCaseDetail.steptype = checkIsEmptyObject(testStepsList[i][3])
                testCaseDetail.stepname = checkIsEmptyObject(testStepsList[i][4])
                testCaseDetail.value = checkIsEmptyObject(testStepsList[i][5])
                testCaseDetail.findelementby = checkIsEmptyObject(testStepsList[i][6])
                testCaseDetail.element = checkIsEmptyObject(testStepsList[i][7])
                testCaseDetail.assertiontype = checkIsEmptyObject(testStepsList[i][8])
                testCaseDetail.url = checkIsEmptyObject(testStepsList[i][9])
                testCaseDetail.action = checkIsEmptyObject(testStepsList[i][10])
                testCaseDetail.selectby = checkIsEmptyObject(testStepsList[i][11])
                testCaseDetail.selecttype = checkIsEmptyObject(testStepsList[i][12])
                testCaseDetail.selectvalue = checkIsEmptyObject(testStepsList[i][13])
                testCaseDetail.switchto = checkIsEmptyObject(testStepsList[i][14])
                testCaseDetail.switchvalue = checkIsEmptyObject(testStepsList[i][15])
                testCaseDetail.timeinseconds = checkIsEmptyObject(testStepsList[i][16])
                testCaseDetail.actual = checkIsEmptyObject(testStepsList[i][17])
                testCaseDetail.expected = checkIsEmptyObject(testStepsList[i][18])
                testCaseDetail.comparator = checkIsEmptyObject(testStepsList[i][19])
                testCaseDetail.variablename = checkIsEmptyObject(testStepsList[i][20])
                testCaseDetail.variabletype = checkIsEmptyObject(testStepsList[i][21])
                testCaseDetail.profilename = checkIsEmptyObject(testStepsList[i][22])
                testCaseDetail.dbprocname = checkIsEmptyObject(testStepsList[i][23])
                testCaseDetail.contenttype = checkIsEmptyObject(testStepsList[i][24])
                testCaseDetail.filename = checkIsEmptyObject(testStepsList[i][25])
                testCaseDetail.resultstatus = 0
                stepIdCount = stepIdCount + 10
            } catch (error) {
                console.log(error)
                openNotificationWithIcon('Error', 'Automation error: populateTestStepsFromExcel - ', error.toString())
            }
            updatedCaseList.push(testCaseDetail)
        }
    }
    return updatedCaseList
}

export function getTotalSteps(dataList) {
    let totalSteps = 0
    if (dataList.length > 0) {
        totalSteps = dataList.length
    }
    return totalSteps
}

export function getLastUpdateStatus(dataList) {
    let status = testStatus.NotAvailable
    if (dataList.length > 0) {
        status = testStatus.Success
        for (let i = 0; i < dataList.length; i += 1) {
            if (dataList[i].resultstatus === 0) {
                status = testStatus.Fail
                break
            }
        }
    } else {
        status = testStatus.NotAvailable
    }
    return status
}

export function getLastProcessDate(dataList) {
    let processedDate = ''
    if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i += 1) {
            processedDate = dataList[i].executionend
        }
    }
    return processedDate
}

export const testStatus = {
    Success: 'Success',
    Error: 'Error',
    Fail: 'Fail',
    NotAvailable: 'Not Available',
}

export const testCaseOperation = {
    TestCase_GetByOwner: 'TestCase_GetByOwner',
    TestCase_GetById: 'TestCase_GetById',
    TestCase_DeleteById: 'TestCase_DeleteById',
    TestCase_UpdateById: 'TestCase_UpdateById',
    TestCase_UpdateById_Post: 'TestCase_UpdateById_Post',
    TestCase_Insert: 'TestCase_Insert',
    TestCase_Execute: 'TestCase_Execute',

    Operation_Delete_TestCase: 'Operation_Delete_TestCase',
    Operation_Add_TestCase: 'Operation_Add_TestCase',
    Operation_Edit_TestCase: 'Operation_Edit_TestCase',
    Operation_Edit_Cancel_TestCase: 'Operation_Edit_Cancel_TestCase',
    Operation_Insert_TestCase: 'Operation_Insert_TestCase',
    Operation_Update_TestCase: 'Operation_Update_TestCase',
    Operation_Update_Post_TestCase: 'Operation_Update_Post_TestCase',
    Operation_Retrieve_TestSteps: 'Operation_Retrieve_TestSteps',

    Operation_Load_StepsFromExcel: 'Operation_Edit_TestSteps',
    Operation_Update_TestSteps: 'Operation_Update_TestSteps',
    Operation_Set_ExecutionResult: 'Operation_Set_ExecutionResult',
    Operation_Execute_TestSteps: 'Operation_Execute_TestSteps',
    Operation_Execute_Parallel: 'Operation_Execute_Parallel',

}

export function testCaseUrl(operation) {
    let url
    const owner = localStorage.getItem('owner');
    switch (operation) {
        case testCaseOperation.TestCase_GetByOwner:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_GetByOwner}/${owner}`
            return url
        case testCaseOperation.TestCase_GetById:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_GetById}/`
            return url
        case testCaseOperation.TestCase_DeleteById:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_DeleteById}/`
            return url
        case testCaseOperation.TestCase_UpdateById:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_UpdateById}/`
            return url
        case testCaseOperation.TestCase_UpdateById_Post:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_UpdateById_Post}/`
            return url
        case testCaseOperation.TestCase_Insert:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_Insert}`
            return url
        case testCaseOperation.TestCase_Execute:
            url = `${siteConfig.apiLambdaUrl}${backEndApiPaths.TestCase_Execute}`
            return url
        default:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_GetByOwner}/${owner}`
            return url
    }
}

export const GetTestCaseFromOwnerList = (ownerTestCases, lookupTestId) => {
    let matchedTestCase = null
    ownerTestCases.forEach(testCase => {
        if (testCase.testid === lookupTestId) {
            matchedTestCase = testCase
        }
    });
    return matchedTestCase
}
