import actions from './actions'

const initState = {
    ownerParameters: [],
    currentParameter: {
        parameterId: null,
        description: null,
        parametername: null,
        parametervalue: null,
        owner: null,
        lastupdatedate: null
    }
}

export default function ParameterReducer(state = initState, {type, ...action}) {
    switch (type) {
        case actions.MANAGE_PARAMETER_ACTION:
            return {
                ...state,
            };
        case actions.MANAGE_PARAMETER_SUCCESS:
            return {
                ...state,
                currentParameter: action.currentParameter == null ? initState.currentParameter : action.currentParameter,
                ownerParameters: action.ownerParameters == null ? initState.ownerParameters : action.ownerParameters,
            };
        default:
            return state
    }
}
