/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import siteConfig from '@isoAm/config/site.config'
import {backEndApiPaths, checkIsEmptyObject} from '@isoAm/config/automationConfig'

export function updateNullFields(dataList) {
    const parameterList = []
    if (dataList) {
        for (let i = 0; i < dataList.length; i += 1) {
            const header1 = {}
            header1.parameterId = dataList[i].parameterId
            header1.description = checkIsEmptyObject(dataList[i].description)
            header1.parametername = checkIsEmptyObject(dataList[i].parametername)
            header1.parametervalue = checkIsEmptyObject(dataList[i].parametervalue)
            header1.owner = checkIsEmptyObject(dataList[i].owner)
            header1.lastupdatedate = checkIsEmptyObject(dataList[i].lastupdatedate)
            parameterList.push(header1)
        }
    }
    return parameterList
}

export const ParameterOperation = {
    Parameter_Retrieve: 'Parameter_Retrieve',
    Parameter_Insert: 'Parameter_Insert',
    Parameter_Update: 'Parameter_Update',
    Parameter_Delete: 'Parameter_Delete',
    Parameter_EditData: 'Parameter_EditData',
    Parameter_Edit_Cancel: 'Parameter_Edit_Cancel',
}

export const ApiOperation = {
    GetByOwner: 'GetByOwner',
    GetById: 'GetById',
    PostInsert: 'PostInsert',
    PostUpdate: 'PostUpdate',
    PostDelete: 'PostDelete',
    GetByProfile: 'GetByProfile',
}

export function ParameterApiUrl(operation) {
    let url
    const owner = localStorage.getItem('owner');
    switch (operation) {
        case ApiOperation.GetByOwner:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Parameter_GetByOwner}/${owner}`
            return url
        case ApiOperation.GetById:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Parameter_GetById}/`
            return url
        case ApiOperation.PostInsert:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Parameter_Insert}/`
            return url
        case ApiOperation.PostUpdate:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Parameter_UpdateById}/`
            return url
        case ApiOperation.PostDelete:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Parameter_DeleteById}/`
            return url
        default:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_GetByOwner}/${owner}`
            return url
    }
}
