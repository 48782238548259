/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

const actions = {
    MANAGE_SUITE_ACTION: 'MANAGE_SUITE_ACTION',
    MANAGE_SUITE_SUCCESS: 'MANAGE_SUITE_SUCCESS',

    manageSuiteAction: (operationType, currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail) => ({
        type: actions.MANAGE_SUITE_ACTION,
        payload: {operationType, currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail},
    }),

    manageSuiteSuccess: (currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail) => ({
        type: actions.MANAGE_SUITE_SUCCESS,
        currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail
    }),
}

export default actions
