const actions = {
    RETRIEVE_TEST_CASES: 'RETRIEVE_TEST_CASES',
    MANAGE_TEST_CASE: 'MANAGE_TEST_CASE',
    MANAGE_TEST_CASE_SUCCESS: 'MANAGE_TEST_CASE_SUCCESS',
    EDIT_TEST_CASE: 'EDIT_TEST_CASE',

    MANAGE_TEST_STEPS: 'MANAGE_TEST_STEPS',
    MANAGE_TEST_STEPS_SUCCESS: 'MANAGE_TEST_STEPS_SUCCESS',

    retrieveTestCases: ownerTestCases => ({type: actions.RETRIEVE_TEST_CASES, ownerTestCases}),

    manageTestCaseAction: (operationType, currentTestCase, ownerTestCases) => ({
        type: actions.MANAGE_TEST_CASE,
        payload: {operationType, currentTestCase, ownerTestCases},
    }),

    manageTestCaseSuccess: (testCaseModalActive, currentTestCase, ownerTestCases) => ({
        type: actions.MANAGE_TEST_CASE_SUCCESS,
        testCaseModalActive,
        currentTestCase,
        ownerTestCases
    }),

    editTestCase: (currentTestCase) => ({
        type: actions.EDIT_TEST_CASE,
        currentTestCase: currentTestCase
    }),

    manageTestStepAction: (operationType, currentTestCase, ownerTestCases) => ({
        type: actions.MANAGE_TEST_STEPS,
        payload: {operationType, currentTestCase, ownerTestCases},
    }),

    manageTestStepActionSuccess: (testStepModalActive, currentTestCase, ownerTestCases) => ({
        type: actions.MANAGE_TEST_STEPS_SUCCESS,
        testStepModalActive,
        currentTestCase,
        ownerTestCases
    }),
}
export default actions
