import actions from './actions'
import moment from "moment";
import {dateFormat} from '@isoAm/config/automationConfig'

const initState = {
    testHistoryList: [],
    startDateTime: moment().subtract(2, 'hour').utc().format(dateFormat),
    endDateTime: moment().add(1, 'hour').utc().format(dateFormat),
}

export default function testHistoryReducer(state = initState, {type, ...action}) {
    switch (type) {
        case actions.RETRIEVE_TEST_HISTORY:
            return {
                ...state,
                testHistoryList: action.testHistoryList,
            };
        case actions.MANAGE_TEST_HISTORY:
            return {
                ...state,
            };
        case actions.MANAGE_TEST_HISTORY_SUCCESS:
            return {
                ...state,
                startDateTime: action.startDateTime == null ? initState.startDateTime : action.startDateTime,
                endDateTime: action.endDateTime == null ? initState.endDateTime : action.endDateTime,
                testHistoryList: action.testHistoryList == null ? initState.testHistoryList : action.testHistoryList,
            };
        default:
            return state
    }
}
