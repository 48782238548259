import {all, put, takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm'
import actions from './actions'
import {historyOperation} from '@isoAm/components/service/historyService'
import openNotificationWithIcon from '@isoAm/components/Notification'

function* sagaManageTestHistory({payload}) {
    const {operationType, startDateTime, endDateTime, testHistoryList} = payload
    try {
        switch (operationType) {
            case historyOperation.History_SetDates:
                yield put(actions.manageTestHistorySuccess(startDateTime, endDateTime, testHistoryList))
                break
            case historyOperation.History_GetByDate:
                yield put(actions.manageTestHistorySuccess(startDateTime, endDateTime, testHistoryList))
                break
            default:
                break
        }
    } catch (error) {
        console.log(error)
        openNotificationWithIcon('error', 'Automation error: sagaManageTestHistory - ', error.toString())
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.MANAGE_TEST_HISTORY, sagaManageTestHistory)])
}