const actions = {
    MANAGE_PARAMETER_ACTION: 'MANAGE_PARAMETER_ACTION',
    MANAGE_PARAMETER_SUCCESS: 'MANAGE_PARAMETER_SUCCESS',

    manageParameterAction: (operationType, currentParameter, ownerParameters) => ({
        type: actions.MANAGE_PARAMETER_ACTION,
        payload: {operationType, currentParameter, ownerParameters},
    }),

    manageParameterSuccess: (currentParameter, ownerParameters) => ({
        type: actions.MANAGE_PARAMETER_SUCCESS,
        currentParameter,
        ownerParameters
    }),

}
export default actions
