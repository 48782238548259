const actions = {
    MANAGE_DATA: 'MANAGE_DATA',
    MANAGE_SUCCESS: 'MANAGE_SUCCESS',

    manageDataAction: (operationType, currentConnection, ownerConnections) => ({
        type: actions.MANAGE_DATA,
        payload: {operationType, currentConnection, ownerConnections},
    }),

    manageDataSuccess: (modalActive, currentConnection, ownerConnections) => ({
        type: actions.MANAGE_SUCCESS,
        modalActive,
        currentConnection,
        ownerConnections
    }),

}
export default actions
