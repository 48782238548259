/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import actions from './actions'

const initState = {
    headerModalActive: false,
    detailModalActive: false,
    currentSuite: {
        suiteid: null,
        name: null,
        description: null,
        startdatetime: null,
        enddatetime: null,
        status: null,
        emailnotification: null,
        owner: null,
        lastupdatedate: null,
        suiteDetails: [
            {
                id: null,
                testid: null,
                startdatetime: null,
                enddatetime: null,
                browser: null,
                totalsteps: null,
                stepscompleted: null,
                jiradefect: null,
                emailnotification: null
            },
        ],
    },
    ownerSuiteList: [],
    ownerTestIdList: [],
    suiteDetail: {
        id: null,
        testid: null,
        startdatetime: null,
        enddatetime: null,
        browser: null,
        totalsteps: null,
        stepscompleted: null,
        jiradefect: null,
        emailnotification: null,
        lastupdatedate: null
    },
}

export default function Suite_Reducer(state = initState, {type, ...action}) {
    switch (type) {
        case actions.MANAGE_SUITE_ACTION:
            return {
                ...state,
            };
        case actions.MANAGE_SUITE_SUCCESS:
            return {
                ...state,
                currentSuite: action.currentSuite == null ? initState.currentSuite : action.currentSuite,
                ownerSuiteList: action.ownerSuiteList == null ? initState.ownerSuiteList : action.ownerSuiteList,
                ownerTestIdList: action.ownerTestIdList == null ? initState.ownerTestIdList : action.ownerTestIdList,
                suiteDetail: action.suiteDetail == null ? initState.suiteDetail : action.suiteDetail
            };
        default:
            return state
    }
}
