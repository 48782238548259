import {all, fork, put, takeEvery} from 'redux-saga/effects';
import {createBrowserHistory} from 'history';

import {clearToken, getToken} from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();

export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function* ({payload}) {
        const {token} = payload;
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: token,
                profile: 'Profile',
            });
        } else {
            yield put({type: actions.LOGIN_ERROR});
        }
    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem('id_token', payload.token);
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        clearToken();
        yield put(history.push('/'));
    });
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get('idToken');
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token,
                profile: 'Profile',
            });
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
    ]);
}
