/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import {all, put, takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm'
import {Suite_Operation} from '@isoAm/components/service/SuiteService'
import actions from './actions'
import openNotificationWithIcon from '@isoAm/components/Notification'


function* sagaManageSuite({payload}) {
    const {
        operationType,
        currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail
    } = payload
    try {
        switch (operationType) {
            case Suite_Operation.Suite_Retrieve:
                yield put(actions.manageSuiteSuccess(currentSuite, ownerSuiteList, ownerTestIdList, null))
                break
            case Suite_Operation.Suite_EditData:
                yield put(actions.manageSuiteSuccess(currentSuite, ownerSuiteList, ownerTestIdList, null))
                break
            case Suite_Operation.Suite_Edit_Cancel:
                yield put(actions.manageSuiteSuccess(null, ownerSuiteList, ownerTestIdList, null))
                break
            case Suite_Operation.Suite_Insert:
                yield put(actions.manageSuiteSuccess(null, ownerSuiteList, ownerTestIdList, null))
                break
            case Suite_Operation.Suite_Update:
                let updatedSuites = [];
                ownerSuiteList.forEach(currentRow => {
                    if (currentRow.suiteid === currentSuite.suiteid) {
                        updatedSuites.push(currentSuite);
                    } else {
                        updatedSuites.push(currentRow);
                    }
                });
                yield put(actions.manageSuiteSuccess(currentSuite, updatedSuites, ownerTestIdList, suiteDetail))
                break
            case Suite_Operation.Suite_Set_Current:
                let retrieveSuite = {};
                let passedSuiteId = currentSuite[0]
                ownerSuiteList.forEach(currentRow => {
                    if (currentRow.suiteid === passedSuiteId) {
                        retrieveSuite = currentRow
                    }
                });
                yield put(actions.manageSuiteSuccess(retrieveSuite, ownerSuiteList, ownerTestIdList, null))
                break
            case Suite_Operation.Suite_Detail_Set_Current:
                yield put(actions.manageSuiteSuccess(currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail))
                break
            case Suite_Operation.Suite_Detail_EditData:
                yield put(actions.manageSuiteSuccess(currentSuite, ownerSuiteList, ownerTestIdList, suiteDetail))
                break
            case Suite_Operation.Suite_Detail_Insert:
                yield put(actions.manageSuiteSuccess(null, ownerSuiteList, ownerTestIdList, null))
                break
            default:
                break
        }
    } catch (error) {
        console.log(error)
        openNotificationWithIcon('error', 'Automation error: SagaManageSuite - ', error.toString())
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.MANAGE_SUITE_ACTION, sagaManageSuite)])
}
