import defaultTheme from './default';
import customTheme from './custom';

const themes = {
    defaultTheme,
    customTheme,
};

export const themeConfig = {
    topbar: 'theme6',
    sidebar: 'theme6',
    layout: 'theme6',
    theme: 'defaultTheme',
};

export default themes;
