import actions from './actions'

const initState = {
    modalActive: false,
    ownerConnections: [],
    currentConnection: {
        connectionId: null,
        awsAccessKeyId: null,
        awsSecretAccessKey: null,
        connectionHost: null,
        connectionPassword: null,
        connectionPort: null,
        connectionServer: null,
        connectionType: null,
        connectionUrl: null,
        connectionUser: null,
        databasename: null,
        databaseschema: null,
        databasetype: null,
        description: null,
        encoded: null,
        owner: null,
        profilename: null
    }
}

export default function ConnectionReducer(state = initState, {type, ...action}) {
    switch (type) {
        case actions.MANAGE_DATA:
            return {
                ...state,
            };
        case actions.MANAGE_SUCCESS:
            return {
                ...state,
                modalActive: action.modalActive == null ? initState.modalActive : action.modalActive,
                currentConnection: action.currentConnection == null ? initState.currentConnection : action.currentConnection,
                ownerConnections: action.ownerConnections == null ? initState.ownerConnections : action.ownerConnections,
            };
        case actions.EDIT_DATA:
            return {
                ...state
            }
        default:
            return state
    }
}
