/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import siteConfig from '@isoAm/config/site.config'
import {backEndApiPaths, checkIsEmptyObject} from '@isoAm/config/automationConfig'

export function updateNullFields(dataList) {
    const connectionList = []
    if (dataList) {
        for (let i = 0; i < dataList.length; i += 1) {
            const header1 = {}
            header1.connectionId = dataList[i].connectionId
            header1.awsAccessKeyId = checkIsEmptyObject(dataList[i].awsAccessKeyId)
            header1.awsSecretAccessKey = checkIsEmptyObject(dataList[i].awsSecretAccessKey)
            header1.connectionHost = checkIsEmptyObject(dataList[i].connectionHost)
            header1.connectionPassword = checkIsEmptyObject(dataList[i].connectionPassword)
            header1.connectionPort = checkIsEmptyObject(dataList[i].connectionPort)
            header1.connectionServer = checkIsEmptyObject(dataList[i].connectionServer)
            header1.connectionType = checkIsEmptyObject(dataList[i].connectionType)
            header1.connectionUrl = checkIsEmptyObject(dataList[i].connectionUrl)
            header1.connectionUser = checkIsEmptyObject(dataList[i].connectionUser)
            header1.databasename = checkIsEmptyObject(dataList[i].databasename)
            header1.databaseschema = checkIsEmptyObject(dataList[i].databaseschema)
            header1.databasetype = checkIsEmptyObject(dataList[i].databasetype)
            header1.description = checkIsEmptyObject(dataList[i].description)
            header1.owner = checkIsEmptyObject(dataList[i].owner)
            header1.profilename = checkIsEmptyObject(dataList[i].profilename)
            header1.provider = checkIsEmptyObject(dataList[i].provider)
            header1.additionalParameters = checkIsEmptyObject(dataList[i].additionalParameters)
            header1.userpool = checkIsEmptyObject(dataList[i].userpool)
            header1.lastupdatedate = checkIsEmptyObject(dataList[i].lastupdatedate)
            connectionList.push(header1)
        }
    }
    return connectionList
}

export function getLastUpdateStatus(dataList) {
    let status = testStatus.NotAvailable
    if (dataList.length > 0) {
        status = testStatus.Success
        for (let i = 0; i < dataList.length; i += 1) {
            if (dataList[i].resultstatus === 0) {
                status = testStatus.Fail
                break
            }
        }
    } else {
        status = testStatus.NotAvailable
    }
    return status
}

export const testStatus = {
    Success: 'Success',
    Error: 'Error',
    Fail: 'Fail',
    NotAvailable: 'Not Available',
}

export const DataOperation = {
    Retrieve: 'Retrieve',
    Insert: 'Insert',
    Update: 'Update',
    Delete: 'Delete',
    EditData: 'EditData',
    Edit_Cancel: 'Edit_Cancel',
    Test_Connection: 'Test_Connection'
}

export const ApiOperation = {
    GetByOwner: 'GetByOwner',
    GetById: 'GetById',
    PostInsert: 'PostInsert',
    PostUpdate: 'PostUpdate',
    PostDelete: 'PostDelete',
    GetByProfile: 'GetByProfile',
}

export function ConnectionApiUrl(operation) {
    let url
    const owner = localStorage.getItem('owner');
    switch (operation) {
        case ApiOperation.GetByOwner:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Connection_GetByOwner}/${owner}`
            return url
        case ApiOperation.GetById:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Connection_GetById}/`
            return url
        case ApiOperation.PostInsert:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Connection_Insert}/`
            return url
        case ApiOperation.PostUpdate:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Connection_UpdateById}/`
            return url
        case ApiOperation.PostDelete:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Connection_DeleteById}/`
            return url
        case ApiOperation.GetByProfile:
            url = `${siteConfig.apiLambdaUrl}${backEndApiPaths.Connection_GetByProfile}`
            return url
        default:
            url = `${siteConfig.apiUrl}${backEndApiPaths.TestCase_GetByOwner}/${owner}`
            return url
    }
}

export const GetTestCaseFromOwnerList = (ownerTestCases, lookupTestId) => {
    let matchedTestCase = null
    ownerTestCases.forEach(testCase => {
        if (testCase.testid === lookupTestId) {
            matchedTestCase = testCase
        }
    });
    return matchedTestCase
}
