/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import siteConfig from '@isoAm/config/site.config'
import {backEndApiPaths, checkIsEmptyObject, dateFormat} from '@isoAm/config/automationConfig'
import openNotificationWithIcon from '@isoAm/components/Notification'
import moment from "moment";

export function updateNullFields(dataList) {
    const testCaseList = []
    if (dataList) {
        for (let i = 0; i < dataList.length; i += 1) {
            const header1 = {}
            header1.rownum = dataList[i].rownum
            header1.testid = dataList[i].testid
            header1.runid = dataList[i].runid
            header1.triggeredby = checkIsEmptyObject(dataList[i].triggeredby)
            header1.resultstatus = checkIsEmptyObject(dataList[i].resultstatus)
            header1.executionstart = checkIsEmptyObject(dataList[i].executionstart)
            header1.executionend = checkIsEmptyObject(dataList[i].executionend)
            header1.executiontimeinseconds = checkIsEmptyObject(dataList[i].executiontimeinseconds)
            header1.executionResult = dataList[i].executionResult
            testCaseList.push(header1)
        }
    }
    return testCaseList
}

export function populateTestStepsFromExcel(testStepsList, testid) {
    const updatedCaseList = []
    let stepIdCount = 5
    if (testStepsList) {
        for (let i = 0; i < testStepsList.length; i += 1) {
            let testCaseDetail = {};
            try {
                // testCaseDetail.screen =
                // console.log("Got testStepsList + " + testStepsList[i])
                testCaseDetail.id = null
                testCaseDetail.testid = testid
                testCaseDetail.screen = checkIsEmptyObject(testStepsList[i][1])
                testCaseDetail.stepid = stepIdCount
                testCaseDetail.description = checkIsEmptyObject(testStepsList[i][2])
                testCaseDetail.steptype = checkIsEmptyObject(testStepsList[i][3])
                testCaseDetail.stepname = checkIsEmptyObject(testStepsList[i][4])
                testCaseDetail.value = checkIsEmptyObject(testStepsList[i][5])
                testCaseDetail.findelementby = checkIsEmptyObject(testStepsList[i][6])
                testCaseDetail.element = checkIsEmptyObject(testStepsList[i][7])
                testCaseDetail.assertiontype = checkIsEmptyObject(testStepsList[i][8])
                testCaseDetail.url = checkIsEmptyObject(testStepsList[i][9])
                testCaseDetail.action = checkIsEmptyObject(testStepsList[i][10])
                testCaseDetail.selectby = checkIsEmptyObject(testStepsList[i][11])
                testCaseDetail.selecttype = checkIsEmptyObject(testStepsList[i][12])
                testCaseDetail.selectvalue = checkIsEmptyObject(testStepsList[i][13])
                testCaseDetail.switchto = checkIsEmptyObject(testStepsList[i][14])
                testCaseDetail.switchvalue = checkIsEmptyObject(testStepsList[i][15])
                testCaseDetail.timeinseconds = checkIsEmptyObject(testStepsList[i][16])
                testCaseDetail.actual = checkIsEmptyObject(testStepsList[i][17])
                testCaseDetail.expected = checkIsEmptyObject(testStepsList[i][18])
                testCaseDetail.comparator = checkIsEmptyObject(testStepsList[i][19])
                testCaseDetail.variablename = checkIsEmptyObject(testStepsList[i][20])
                testCaseDetail.variabletype = checkIsEmptyObject(testStepsList[i][21])
                testCaseDetail.profilename = checkIsEmptyObject(testStepsList[i][22])
                testCaseDetail.dbprocname = checkIsEmptyObject(testStepsList[i][23])
                testCaseDetail.contenttype = checkIsEmptyObject(testStepsList[i][24])
                testCaseDetail.filename = checkIsEmptyObject(testStepsList[i][25])
                stepIdCount++
            } catch (error) {
                console.log(error)
                openNotificationWithIcon('Error', 'Automation error: populateTestStepsFromExcel - ', error.toString())
            }
            updatedCaseList.push(testCaseDetail)
        }
    }
    return updatedCaseList
}

// this value is used to update in history table
export const triggeredBy = {
    UserInSequence: 'US',
    UserInParallel: 'UP',
    Suite: 'S'
}

export const testStatus = {
    Success: 'Success',
    Error: 'Error',
    Fail: 'Fail',
    NotAvailable: 'Not Available',
}

export const historyOperation = {
    History_GetById: 'History_GetById',
    History_Insert: 'History_Insert',
    History_GetByDate: 'History_GetByDate',
    History_SetDates: 'History_SetDates',
}

export function historyUrl(operation) {
    let url
    switch (operation) {
        case historyOperation.History_GetById:
            url = `${siteConfig.apiUrl}${backEndApiPaths.History_GetById}/`
            return url
        case historyOperation.History_Insert:
            url = `${siteConfig.apiUrl}${backEndApiPaths.History_Insert}`
            return url
        case historyOperation.History_GetByDate:
            url = `${siteConfig.apiUrl}${backEndApiPaths.History_GetByDate}`
            return url
        default:
            return null
    }
}

export function getResultStatus(executionResult) {
    let executionStatus = null;
    let obj = JSON.parse(executionResult);
    if (executionResult) {
        for (let i = 0; i < obj.length; i += 1) {
            if (obj[i].resultstatus === 0) {
                executionStatus = 0
                break
            } else {
                executionStatus = 1
            }
        }
    }
    return executionStatus
}

export function getTestId(executionResult) {
    let obj = JSON.parse(executionResult);
    return obj[0].testid
}

export function getExecutionStart(executionResult) {
    let obj = JSON.parse(executionResult);
    return moment(new Date(obj[0].executionstart)).format(dateFormat)
}

export function getExecutionEnd(executionResult) {
    let executionEndTime = null;
    let obj = JSON.parse(executionResult);
    if (executionResult) {
        for (let i = 0; i < obj.length; i += 1) {
            let isValidDate = Date.parse(obj[i].executionend);
            if (isNaN(isValidDate)) {
                // do not get this date.
            } else {
                executionEndTime = obj[i].executionend
            }
        }
    }
    return moment(new Date(executionEndTime)).format(dateFormat)
}

export function setTestHistory(executionResult, triggeredBy, uuidValue) {
    const executionStatus = getResultStatus(executionResult)
    const startTime = getExecutionStart(executionResult)
    const endTime = getExecutionEnd(executionResult)
    const date1 = new Date(startTime).getTime();
    const date2 = new Date(endTime).getTime();
    const diff = date2 - date1;
    const seconds = diff / 1000;

    const initState = {
        currentTestCase: {
            testid: getTestId(executionResult),
            runid: uuidValue,
            resultstatus: executionStatus,
            executionstart: startTime,
            executionend: endTime,
            executiontimeinseconds: seconds,
            executionResult: executionResult,
            lastupdatedate: moment().utc().format(dateFormat),
            triggeredby: triggeredBy
        }
    }
    return initState.currentTestCase
}