import {all} from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import suiteSaga from '@isoAm/redux/suite/saga'
import testCaseSaga from '@isoAm/redux/testcase/saga'
import testHistorySaga from '@isoAm/redux/history/saga'
import connectionSaga from '@isoAm/redux/connection/saga'
import parameterSaga from '@isoAm/redux/parameters/saga'


export default function* rootSaga(getState) {
    yield all([authSaga(), ecommerceSaga(),
        suiteSaga(),
        testCaseSaga(),
        testHistorySaga(),
        connectionSaga(),
        parameterSaga()
    ]);
}
