export default {
    siteName: 'Automation',
    siteFullName: 'AutomationForApp',
    siteIcon: 'ion-flash',
    footerText: `AutomationForApp @ ${new Date().getFullYear()} Created by AutomationForApp, Team`,
    enableAnimatedRoute: false,
    // apiUrl: 'http://localhost:8080',
    // apiLambdaUrl: 'http://localhost:8081',
    apiUrl: 'https://automationforapp.com:8080/backend',
    apiLambdaUrl: 'https://automationforapp.com:8080/processing',

    apiAuthUrl: 'http://localhost:8080/auth/',
    google: {
        analyticsKey: 'UA-xxxxxxxxx-1'
    },
    restMethod: {
        POST: 'POST',
        GET: 'GET'
    },
    dashboard: '/dashboard',
    pageSize90: '90vh',

    suiteOperation: {
        POST: 'POST',
        GET: 'GET'
    },
};
