import actions from './actions'

const initState = {
    testCaseModalActive: false,
    testStepModalActive: false,
    ownerTestCases: [],
    currentTestCase: {
        testid: null,
        description: null,
        lastupdatedate: null,
        name: null,
        totalsteps: 0,
        owner: null,
        testCaseDetails: [
            {
                id: 0,
                screen: 0,
                stepid: null,
                description: null,
                steptype: null,
                stepname: null,
                resultstatus: 0,
                resultduration: 0,
                resultvalue: null,
                executionstart: null,
                executionend: null,
                findelementby: null,
                element: null,
                value: null,
                assertiontype: null,
                url: null,
                action: '',
                expected: null,
                actual: null,
                comparator: null,
                selecttype: null,
                selectby: null,
                selectvalue: null,
                switchto: null,
                switchvalue: null,
                timeinseconds: 0,
                profilename: null,
                dbprocname: null,
                variablename: null,
                variabletype: null,
                imagefile: null,
                lastupdatedate: null
            },
        ],
    },
    Testcaseheader: {
        testid: null,
        description: null,
        lastupdatedate: null,
        name: null,
        totalsteps: 0,
        owner: null,
        testCaseDetails: [
            {
                id: 0,
                screen: 0,
                stepid: null,
                description: null,
                steptype: null,
                stepname: null,
                resultstatus: 0,
                resultduration: 0,
                resultvalue: null,
                executionstart: null,
                executionend: null,
                findelementby: null,
                element: null,
                value: null,
                assertiontype: null,
                url: null,
                action: 'asdas',
                expected: null,
                actual: null,
                comparator: null,
                selecttype: null,
                selectby: null,
                selectvalue: null,
                switchto: null,
                switchvalue: null,
                timeinseconds: 0,
                profilename: null,
                dbprocname: null,
                variablename: null,
                variabletype: null,
                imagefile: null,
                lastupdatedate: null
            },
        ],
    },
    TestCaseDetail: [
        {
            id: 0,
            screen: 0,
            stepid: null,
            description: null,
            steptype: null,
            stepname: null,
            resultstatus: 0,
            resultduration: 0,
            resultvalue: null,
            executionstart: null,
            executionend: null,
            findelementby: null,
            element: null,
            value: null,
            assertiontype: null,
            url: null,
            action: null,
            expected: null,
            actual: null,
            comparator: null,
            selecttype: null,
            selectby: null,
            selectvalue: null,
            switchto: null,
            switchvalue: null,
            timeinseconds: 0,
            profilename: null,
            dbprocname: null,
            variablename: null,
            variabletype: null,
            imagefile: null,
            lastupdatedate: null
        },
    ],
}

export default function testCaseReducer(state = initState, {type, ...action}) {
    switch (type) {
        case actions.RETRIEVE_TEST_CASES:
            return {
                ...state,
                ownerTestCases: action.ownerTestCases,
            };
        case actions.MANAGE_TEST_CASE:
            return {
                ...state,
            };
        case actions.MANAGE_TEST_CASE_SUCCESS:
            return {
                ...state,
                testCaseModalActive: action.testCaseModalActive == null ? initState.testCaseModalActive : action.testCaseModalActive,
                currentTestCase: action.currentTestCase == null ? initState.currentTestCase : action.currentTestCase,
                ownerTestCases: action.ownerTestCases == null ? initState.ownerTestCases : action.ownerTestCases,
            };
        case actions.EDIT_TEST_CASE:
            return {
                ...state
            }
        case actions.MANAGE_TEST_STEPS:
            return {
                ...state,
            };
        case actions.MANAGE_TEST_STEPS_SUCCESS:
            return {
                ...state,
                testStepModalActive: action.testStepModalActive == null ? initState.testStepModalActive : action.testStepModalActive,
                currentTestCase: action.currentTestCase == null ? initState.currentTestCase : action.currentTestCase,
                ownerTestCases: action.ownerTestCases == null ? initState.ownerTestCases : action.ownerTestCases,
            };
        default:
            return state
    }
}
