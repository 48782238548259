const actions = {
    RETRIEVE_TEST_HISTORY: 'RETRIEVE_TEST_HISTORY',
    MANAGE_TEST_HISTORY: 'MANAGE_TEST_HISTORY',
    MANAGE_TEST_HISTORY_SUCCESS: 'MANAGE_TEST_HISTORY_SUCCESS',

    retrieveTestHistory: testHistoryList => ({type: actions.RETRIEVE_TEST_HISTORY, testHistoryList}),

    manageTestHistoryAction: (operationType, startDateTime, endDateTime, testHistoryList) => ({
        type: actions.MANAGE_TEST_HISTORY,
        payload: {operationType, startDateTime, endDateTime, testHistoryList},
    }),

    manageTestHistorySuccess: (startDateTime, endDateTime, testHistoryList) => ({
        type: actions.MANAGE_TEST_HISTORY_SUCCESS,
        startDateTime,
        endDateTime,
        testHistoryList
    }),
}
export default actions
