/*
    Project : Automation front-end
    Created by : Imran Khan
    Created on : 11/15/2020
*/

import siteConfig from '@isoAm/config/site.config'
import {backEndApiPaths, checkIsEmptyObject} from '@isoAm/config/automationConfig'

export function UpdateNullFields_SuiteHeader(dataList) {
    // console.log(`Inside populateSuiteDetail:  + ${dataList}`)
    const suites = []
    if (dataList) {
        for (let i = 0; i < dataList.length; i += 1) {
            let currentSuite;
            currentSuite = {};
            currentSuite.suiteid = dataList[i].suiteid
            currentSuite.name = checkIsEmptyObject(dataList[i].name)
            currentSuite.description = checkIsEmptyObject(dataList[i].description)
            currentSuite.startdatetime = checkIsEmptyObject(dataList[i].startdatetime)
            currentSuite.enddatetime = checkIsEmptyObject(dataList[i].enddatetime)
            currentSuite.status = checkIsEmptyObject(dataList[i].status)
            currentSuite.owner = dataList[i].owner
            currentSuite.lastupdatedate = checkIsEmptyObject(dataList[i].lastupdatedate)
            currentSuite.suiteDetails = dataList[i].suiteDetails
            currentSuite.totalsteps = dataList[i].suiteDetails.length
            suites.push(currentSuite)
        }
    }
    return suites
}

export function UpdateNullFields_SuiteDetail(data) {
    const dataList = data
    const userDataObj = []
    if (dataList) {
        for (let i = 0; i < dataList.length; i += 1) {
            const currentSuite = {}
            currentSuite.id = dataList[i].id
            currentSuite.testid = dataList[i].testid
            currentSuite.startdatetime = dataList[i].startdatetime
            currentSuite.enddatetime = dataList[i].enddatetime
            currentSuite.browser = dataList[i].browser
            currentSuite.stepscompleted = dataList[i].stepscompleted
            currentSuite.jiradefect = dataList[i].jiradefect
            currentSuite.lastupdatedate = dataList[i].lastupdatedate
            userDataObj.push(currentSuite)
        }
    }
    return userDataObj
}

export const Suite_Operation = {
    Suite_Retrieve: 'Suite_Retrieve',
    Suite_Insert: 'Suite_Insert',
    Suite_Update: 'Suite_Update',
    Suite_Delete: 'Suite_Delete',
    Suite_Set_Current: 'Suite_Set_Current',
    Suite_EditData: 'Suite_EditData',
    Suite_Edit_Cancel: 'Suite_Edit_Cancel',
    Suite_Detail_EditData: 'Suite_Detail_EditData',
    Suite_Detail_Edit_Cancel: 'Suite_Detail_Edit_Cancel',
    Suite_Detail_Delete: 'Suite_Detail_Delete',
    Suite_Detail_Set_Current: 'Suite_Detail_Set_Current',
    Suite_Detail_Update: 'Suite_Detail_Update',
    Suite_Detail_Insert: 'Suite_Detail_Insert',
    Suite_Detail_Insert_Save: 'Suite_Detail_Insert_Save',
    Suite_FindOwnerTestIdList: 'Suite_FindOwnerTestIdList'
}

export const Suite_ApiOperation = {
    GetByOwner: 'GetByOwner',
    GetById: 'GetById',
    PostInsert: 'PostInsert',
    PostUpdate: 'PostUpdate',
    PostDelete: 'PostDelete',
    GetByProfile: 'GetByProfile',
}

// export const suiteOperation = {
//     GetFindByOwner: 'operation_getFindByOwner',
//     GetFindTestIdList: 'operation_getFindTestIdList',
//     PostInsertSuite: 'operation_insert_suite',
//     PostDeleteSuite: 'operation_delete_suite',
//     PostSaveSuite: 'operation_save_suite',
//     SelectSuite: 'operation_select_suite',
//     SelectSuiteStep: 'operation_select_suite_step',
//     InsertSuiteStep: 'operation_insert_suite_step',
//     DeleteSuiteStep: 'operation_delete_suite_step',
//     SaveSuiteStep: 'operation_save_suite_step',
//     ToggleSuiteStepModal: 'operation_toggleSuiteStepModal',
//     EDIT_SUITE: 'operation_edit_suite',
// }

export function SuiteApiUrl(type) {
    let url
    const owner = localStorage.getItem('owner');
    switch (type) {
        case Suite_ApiOperation.GetByOwner:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Suite_Get_By_Owner_Path}/${owner}`
            return url
        case Suite_ApiOperation.PostUpdate:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Suite_Update_By_Id_Path}`
            return url
        case Suite_ApiOperation.PostDelete:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Suite_Delete_By_Id_Path}`
            return url
        case Suite_ApiOperation.PostInsert:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Suite_Insert_Path}`
            return url
        case Suite_ApiOperation.GetFindTestIdList:
            url = `${siteConfig.apiUrl}${backEndApiPaths.Suite_TestCase_List_By_Owner_Path}/${owner}`
            return url
        default:
            url = `${siteConfig.apiUrl}suite/owner/${owner}`
            return url
    }
}

export const suiteDetailHeader = [
    {
        title: 'Testid',
        dataIndex: 'testid',
        key: 'testid',
        width: '12%',
        sorter: (a, b) => a.testid - b.testid,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Browser',
        dataIndex: 'browser',
        key: 'browser',
        width: '10%',
    },
    {
        title: 'Steps Completed',
        dataIndex: 'stepscompleted',
        key: 'stepscompleted',
        width: '10%',
    },
    {
        title: 'Start-DateTime',
        key: 'startdatetime',
        dataIndex: 'startdatetime',
        width: '15%',
    },
    {
        title: 'End-DateTime',
        key: 'enddatetime',
        dataIndex: 'enddatetime',
        width: '15%',
    },
    {
        title: 'Jira-Defect',
        key: 'jiradefect',
        dataIndex: 'jiradefect',
        width: '12%',
    },
]

